import { Box } from 'theme-ui'

import SweetRide from './SweetRide'

const Rides = ({ sx, ...props }) => (
  <Box className="-with-transition" sx={{ variant: 'hero.rides', ...sx }} {...props}>
    <SweetRide mode="light" />
    <SweetRide mode="dark" />
  </Box>
)

export default Rides
