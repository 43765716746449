import { memo } from 'react'
import { useSetRecoilState } from 'recoil'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { imagesCount } from '../atoms'

const TYPES = {
  light: 'hero-light',
  dark: 'hero-dark',
}

const SweetRide = ({ mode, sx, ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile {
        rides: edges {
          node {
            name
            base
            childImageSharp {
              gatsbyImageData(placeholder: NONE, quality: 95, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  `)

  const { rides } = data.allFile
  const selected = rides.filter((ride) => ride.node.name === TYPES[mode])[0].node
  const image = getImage(selected)

  const setImagesCount = useSetRecoilState(imagesCount)

  return (
    <GatsbyImage
      className={mode + '-ride'}
      sx={{ variant: 'hero.image', ...sx }}
      image={image}
      onLoad={() => setImagesCount((prev) => prev + 1)}
      alt="fabulous pink ride"
      {...props}
    />
  )
}

SweetRide.defaultProps = {
  mode: 'light',
}

export default memo(SweetRide)
