import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useThemeUI } from 'theme-ui'
import { useModes } from '@monobits/color-transitions'

import Rides from '../components/Rides'

const IndexPage = () => {
  const { setTarget, initial, next } = useModes({ context: useThemeUI(null) })

  return (
    <>
      <GatsbySeo title="MAT" />
      <Rides
        as="section" //
        onTouchStart={() => setTarget(next)}
        onTouchEnd={() => setTarget(initial)}
      />
    </>
  )
}

export default IndexPage
